import React, {useEffect} from "react";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import Loadable from "react-loadable";
import LoaderComponent from "../components/LoaderComponent";
import {loadTextsByNamespace} from "../../server/helper/ssrHelper";
import config from "../config/main.config";
import {jumpToTop} from "../helper/util";

const EditHTMLComponent = Loadable({
    loader: () => import(/* webpackChunkName: 'EditHTMLComponent' */"../components/editHTML/EditHTMLComponent"),
    loading: () => LoaderComponent
});

const EditTextComponent = Loadable({
    loader: () => import(/* webpackChunkName: 'EditTextComponent' */"../components/editText/EditTextComponent"),
    loading: () => LoaderComponent
});

const DataPrivacy = () => {

    useEffect(() => {
        if (typeof window !== "undefined") {
            jumpToTop(window);
        }
    }, []);
    const namespace = 'agb';

    return (
        <div className={'grid ui'} style={{paddingTop: '300px'}}>
            <div className={'centered row page-content-wrapper'}>
                <div className={'sixteen wide column'}>
                    <div style={{display: 'none'}}>
                        <BreadCrumbs breadCrumbData={[{title: 'AGB', url: '/agb'}]}/>
                    </div>
                </div>
                <div className={'sixteen wide column'}>
                    <h1><EditTextComponent id={`${namespace}-header-1`} namespace={namespace}/></h1>
                    <EditHTMLComponent id={`${namespace}-text-1`} namespace={namespace}/>
                </div>
            </div>
        </div>
    )
}

DataPrivacy.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'agb').then((text) => {
        return text;
    })
}

export default DataPrivacy
